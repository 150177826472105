export default {
  description: `<p>
            The PowerPoint Generator allows you to efficiently create branded PowerPoint
            presentations with templated slides available for download.
        </p>
        <p>
            To create your template, select all of the specific slides you would like to use from the 
            options below and then click 'Download Template’. The slide options are organized by category.
        </p>
        <p>
            The Standard/Executive template is for a variety of information and presentations; the 
            Academic template is tailored for research or teaching presentations.
            Once you have downloaded your template, you can create your presentation with new 
            content; copy and paste existing content into the template; or select “reuse slides” to 
            include content from an existing presentation. If you do select “reuse slides”, be sure to 
            disable “use source formatting.”
        </p>`,
  templates: [
    {
      value: 'scientific',
      label: 'Scientific Presentation',
      slideCategories: [
        {
          slide_title: 'Cover Slides',
          slides: [
            { value: 1, source: require('../images/slides/scientific/ScientificSlide1.jpeg') },
            { value: 2, source: require('../images/slides/scientific/ScientificSlide2.jpeg') },
            { value: 3, source: require('../images/slides/scientific/ScientificSlide3.jpeg') },
            { value: 4, source: require('../images/slides/scientific/ScientificSlide4.jpeg') }
          ]
        },
        {
          slide_title: 'Content Slides',
          slides: [
            { value: 5, source: require('../images/slides/scientific/ScientificSlide5.jpeg') },
            { value: 6, source: require('../images/slides/scientific/ScientificSlide6.jpeg') },
            { value: 7, source: require('../images/slides/scientific/ScientificSlide7.jpeg') },
            { value: 8, source: require('../images/slides/scientific/ScientificSlide8.jpeg') },
            { value: 9, source: require('../images/slides/scientific/ScientificSlide9.jpeg') },
            { value: 10, source: require('../images/slides/scientific/ScientificSlide10.jpeg') },
            { value: 11, source: require('../images/slides/scientific/ScientificSlide11.jpeg') },
            { value: 12, source: require('../images/slides/scientific/ScientificSlide12.jpeg') },
            { value: 13, source: require('../images/slides/scientific/ScientificSlide13.jpeg') },
            { value: 14, source: require('../images/slides/scientific/ScientificSlide14.jpeg') },
            { value: 15, source: require('../images/slides/scientific/ScientificSlide15.jpeg') },
            { value: 16, source: require('../images/slides/scientific/ScientificSlide16.jpeg') },
            { value: 17, source: require('../images/slides/scientific/ScientificSlide17.jpeg') },
            { value: 18, source: require('../images/slides/scientific/ScientificSlide18.jpeg') },
            { value: 19, source: require('../images/slides/scientific/ScientificSlide19.jpeg') },
            { value: 20, source: require('../images/slides/scientific/ScientificSlide20.jpeg') },
            { value: 21, source: require('../images/slides/scientific/ScientificSlide21.jpeg') },
            { value: 22, source: require('../images/slides/scientific/ScientificSlide22.jpeg') },
            { value: 23, source: require('../images/slides/scientific/ScientificSlide23.jpeg') },
            { value: 24, source: require('../images/slides/scientific/ScientificSlide24.jpeg') },
            { value: 25, source: require('../images/slides/scientific/ScientificSlide25.jpeg') }
          ]
        },
        {
          slide_title: 'Section Divider',
          slides: [
            { value: 26, source: require('../images/slides/scientific/ScientificSlide26.jpeg') },
            { value: 27, source: require('../images/slides/scientific/ScientificSlide27.jpeg') },
            { value: 28, source: require('../images/slides/scientific/ScientificSlide28.jpeg') },
            { value: 29, source: require('../images/slides/scientific/ScientificSlide29.jpeg') },
            { value: 30, source: require('../images/slides/scientific/ScientificSlide30.jpeg') }
          ]
        },
        {
          slide_title: 'Call Out/Call to Action',
          slides: [
            { value: 31, source: require('../images/slides/scientific/ScientificSlide31.jpeg') },
            { value: 32, source: require('../images/slides/scientific/ScientificSlide32.jpeg') },
            { value: 33, source: require('../images/slides/scientific/ScientificSlide33.jpeg') }
          ]
        },
        {
          slide_title: 'End Slide',
          slides: [
            { value: 34, source: require('../images/slides/scientific/ScientificSlide34.jpeg') }
          ]
        }
      ]
    },
    {
      value: 'standard-forevergreen',
      label: 'Standard Presentation - Forevergreen',
      slideCategories: [
        {
          slide_title: 'Cover Slides',
          slides: [
            { value: 1, source: require('../images/slides/forevergreen/ForevergreenSlide1.jpeg') },
            { value: 2, source: require('../images/slides/forevergreen/ForevergreenSlide2.jpeg') },
            { value: 3, source: require('../images/slides/forevergreen/ForevergreenSlide3.jpeg') },
            { value: 4, source: require('../images/slides/forevergreen/ForevergreenSlide4.jpeg') },
            { value: 5, source: require('../images/slides/forevergreen/ForevergreenSlide5.jpeg') },
            { value: 6, source: require('../images/slides/forevergreen/ForevergreenSlide6.jpeg') },
            { value: 7, source: require('../images/slides/forevergreen/ForevergreenSlide7.jpeg') },
            { value: 8, source: require('../images/slides/forevergreen/ForevergreenSlide8.jpeg') },
          ]
        },
        {
          slide_title: 'Content Slides',
          slides: [
            { value: 9, source: require('../images/slides/forevergreen/ForevergreenSlide9.jpeg') },
            { value: 10, source: require('../images/slides/forevergreen/ForevergreenSlide10.jpeg') },
            { value: 11, source: require('../images/slides/forevergreen/ForevergreenSlide11.jpeg') },
            { value: 12, source: require('../images/slides/forevergreen/ForevergreenSlide12.jpeg') },
            { value: 13, source: require('../images/slides/forevergreen/ForevergreenSlide13.jpeg') },
            { value: 14, source: require('../images/slides/forevergreen/ForevergreenSlide14.jpeg') },
            { value: 15, source: require('../images/slides/forevergreen/ForevergreenSlide15.jpeg') },
            { value: 16, source: require('../images/slides/forevergreen/ForevergreenSlide16.jpeg') },
            { value: 17, source: require('../images/slides/forevergreen/ForevergreenSlide17.jpeg') },
            { value: 18, source: require('../images/slides/forevergreen/ForevergreenSlide18.jpeg') },
            { value: 19, source: require('../images/slides/forevergreen/ForevergreenSlide19.jpeg') },            
            { value: 20, source: require('../images/slides/forevergreen/ForevergreenSlide20.jpeg') },            
            { value: 21, source: require('../images/slides/forevergreen/ForevergreenSlide21.jpeg') },
            { value: 22, source: require('../images/slides/forevergreen/ForevergreenSlide22.jpeg') },
            { value: 23, source: require('../images/slides/forevergreen/ForevergreenSlide23.jpeg') },
            { value: 24, source: require('../images/slides/forevergreen/ForevergreenSlide24.jpeg') },
            { value: 25, source: require('../images/slides/forevergreen/ForevergreenSlide25.jpeg') },
            { value: 26, source: require('../images/slides/forevergreen/ForevergreenSlide26.jpeg') },
            { value: 27, source: require('../images/slides/forevergreen/ForevergreenSlide27.jpeg') },
            { value: 28, source: require('../images/slides/forevergreen/ForevergreenSlide28.jpeg') },
            { value: 29, source: require('../images/slides/forevergreen/ForevergreenSlide29.jpeg') },
            { value: 30, source: require('../images/slides/forevergreen/ForevergreenSlide30.jpeg') },
            { value: 31, source: require('../images/slides/forevergreen/ForevergreenSlide31.jpeg') },
            { value: 32, source: require('../images/slides/forevergreen/ForevergreenSlide32.jpeg') },
            { value: 33, source: require('../images/slides/forevergreen/ForevergreenSlide33.jpeg') },
            { value: 34, source: require('../images/slides/forevergreen/ForevergreenSlide34.jpeg') },
            { value: 35, source: require('../images/slides/forevergreen/ForevergreenSlide35.jpeg') },
            { value: 36, source: require('../images/slides/forevergreen/ForevergreenSlide36.jpeg') },
            { value: 37, source: require('../images/slides/forevergreen/ForevergreenSlide37.jpeg') },
            { value: 38, source: require('../images/slides/forevergreen/ForevergreenSlide38.jpeg') },
            { value: 39, source: require('../images/slides/forevergreen/ForevergreenSlide39.jpeg') },
            { value: 40, source: require('../images/slides/forevergreen/ForevergreenSlide40.jpeg') }
          ]
        },
        {
          slide_title: 'Section Dividers',
          slides: [
            { value: 42, source: require('../images/slides/forevergreen/ForevergreenSlide42.jpeg') },
            { value: 43, source: require('../images/slides/forevergreen/ForevergreenSlide43.jpeg') },
            { value: 44, source: require('../images/slides/forevergreen/ForevergreenSlide44.jpeg') },
            { value: 45, source: require('../images/slides/forevergreen/ForevergreenSlide45.jpeg') },
            { value: 46, source: require('../images/slides/forevergreen/ForevergreenSlide46.jpeg') }
          ]
        },
        {
          slide_title: 'Call Out/Call to Action',
          slides: [
            { value: 47, source: require('../images/slides/forevergreen/ForevergreenSlide47.jpeg') },
            { value: 48, source: require('../images/slides/forevergreen/ForevergreenSlide48.jpeg') },
            { value: 49, source: require('../images/slides/forevergreen/ForevergreenSlide49.jpeg') },
            { value: 50, source: require('../images/slides/forevergreen/ForevergreenSlide50.jpeg') },
            { value: 51, source: require('../images/slides/forevergreen/ForevergreenSlide51.jpeg') },
            { value: 52, source: require('../images/slides/forevergreen/ForevergreenSlide52.jpeg') },
          ]
        },
        {
          slide_title: 'End Slide',
          slides: [
            { value: 41, source: require('../images/slides/forevergreen/ForevergreenSlide41.jpeg') }
          ]
        },
        {
          slide_title: 'Brand Fonts & Colors',
          slides: [
            { value: 53, source: require('../images/slides/forevergreen/ForevergreenSlide53.jpeg') }
          ]
        },
        {
          slide_title: 'Icons',
          slides: [
            { value: 54, source: require('../images/slides/forevergreen/ForevergreenSlide54.jpeg') }
          ]
        }
      ]
    },
    {
      value: 'standard-white',
      label: 'Standard Presentation - White',
      slideCategories: [
        {
          slide_title: 'Cover Slides',
          slides: [
            { value: 1, source: require('../images/slides/white/WhiteSlide1.jpeg') },
            { value: 2, source: require('../images/slides/white/WhiteSlide2.jpeg') },
            { value: 3, source: require('../images/slides/white/WhiteSlide3.jpeg') },
            { value: 4, source: require('../images/slides/white/WhiteSlide4.jpeg') },
            { value: 5, source: require('../images/slides/white/WhiteSlide5.jpeg') },
            { value: 6, source: require('../images/slides/white/WhiteSlide6.jpeg') },
            { value: 7, source: require('../images/slides/white/WhiteSlide7.jpeg') },
            { value: 8, source: require('../images/slides/white/WhiteSlide8.jpeg') },
          ]
        },
        {
          slide_title: 'Content Slides',
          slides: [
            { value: 9, source: require('../images/slides/white/WhiteSlide9.jpeg') },
            { value: 10, source: require('../images/slides/white/WhiteSlide10.jpeg') },
            { value: 11, source: require('../images/slides/white/WhiteSlide11.jpeg') },
            { value: 12, source: require('../images/slides/white/WhiteSlide12.jpeg') },
            { value: 13, source: require('../images/slides/white/WhiteSlide13.jpeg') },
            { value: 14, source: require('../images/slides/white/WhiteSlide14.jpeg') },
            { value: 15, source: require('../images/slides/white/WhiteSlide15.jpeg') },
            { value: 16, source: require('../images/slides/white/WhiteSlide16.jpeg') },
            { value: 17, source: require('../images/slides/white/WhiteSlide17.jpeg') },
            { value: 18, source: require('../images/slides/white/WhiteSlide18.jpeg') },
            { value: 19, source: require('../images/slides/white/WhiteSlide19.jpeg') },            
            { value: 20, source: require('../images/slides/white/WhiteSlide20.jpeg') },            
            { value: 21, source: require('../images/slides/white/WhiteSlide21.jpeg') },
            { value: 22, source: require('../images/slides/white/WhiteSlide22.jpeg') },
            { value: 23, source: require('../images/slides/white/WhiteSlide23.jpeg') },
            { value: 24, source: require('../images/slides/white/WhiteSlide24.jpeg') },
            { value: 25, source: require('../images/slides/white/WhiteSlide25.jpeg') },
            { value: 26, source: require('../images/slides/white/WhiteSlide26.jpeg') },
            { value: 27, source: require('../images/slides/white/WhiteSlide27.jpeg') },
            { value: 28, source: require('../images/slides/white/WhiteSlide28.jpeg') },
            { value: 29, source: require('../images/slides/white/WhiteSlide29.jpeg') },
            { value: 30, source: require('../images/slides/white/WhiteSlide30.jpeg') },
            { value: 31, source: require('../images/slides/white/WhiteSlide31.jpeg') },
            { value: 32, source: require('../images/slides/white/WhiteSlide32.jpeg') },
            { value: 33, source: require('../images/slides/white/WhiteSlide33.jpeg') },
            { value: 34, source: require('../images/slides/white/WhiteSlide34.jpeg') },
            { value: 35, source: require('../images/slides/white/WhiteSlide35.jpeg') },
            { value: 36, source: require('../images/slides/white/WhiteSlide36.jpeg') },
            { value: 37, source: require('../images/slides/white/WhiteSlide37.jpeg') },
            { value: 38, source: require('../images/slides/white/WhiteSlide38.jpeg') },
            { value: 39, source: require('../images/slides/white/WhiteSlide39.jpeg') },
            { value: 40, source: require('../images/slides/white/WhiteSlide40.jpeg') }
          ]
        },
        {
          slide_title: 'Section Dividers',
          slides: [
            { value: 42, source: require('../images/slides/white/WhiteSlide42.jpeg') },
            { value: 43, source: require('../images/slides/white/WhiteSlide43.jpeg') },
            { value: 44, source: require('../images/slides/white/WhiteSlide44.jpeg') },
            { value: 45, source: require('../images/slides/white/WhiteSlide45.jpeg') },
            { value: 46, source: require('../images/slides/white/WhiteSlide46.jpeg') }
          ]
        },
        {
          slide_title: 'Call Out/Call to Action',
          slides: [
            { value: 47, source: require('../images/slides/white/WhiteSlide47.jpeg') },
            { value: 48, source: require('../images/slides/white/WhiteSlide48.jpeg') },
            { value: 49, source: require('../images/slides/white/WhiteSlide49.jpeg') },
            { value: 50, source: require('../images/slides/white/WhiteSlide50.jpeg') },
            { value: 51, source: require('../images/slides/white/WhiteSlide51.jpeg') },
            { value: 52, source: require('../images/slides/white/WhiteSlide52.jpeg') },
          ]
        },
        {
          slide_title: 'End Slide',
          slides: [
            { value: 41, source: require('../images/slides/white/WhiteSlide41.jpeg') }
          ]
        },
        {
          slide_title: 'Brand Fonts & Colors',
          slides: [
            { value: 53, source: require('../images/slides/white/WhiteSlide53.jpeg') }
          ]
        },
        {
          slide_title: 'Icons',
          slides: [
            { value: 54, source: require('../images/slides/white/WhiteSlide54.jpeg') }
          ]
        }
      ]
    }
  ]
}
